
@import "~@/common/less/variable.less";

.group-play {
  height: 0.94rem;
  border-top: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .group-img {
    width: 1.12rem;
    height: 0.34rem;

    img {
      width: 1.12rem;
      height: 0.34rem;
    }
  }

  .group-rule {
    height: 0.94rem;
    display: flex;
    align-items: center;

    .rule-item {
      display: flex;
      align-items: center;
      margin-right: 5px;

      div {
        width: 0.28rem;
        height: 0.28rem;
        border-radius: 0.28rem;
        background: var(--main-color);
        font-size: 0.22rem;
        color: #fff;
        margin-right: 2px;
        text-align: center;
        line-height: 0.28rem;
      }

      p {
        font-size: 0.22rem;
        color: #666666;
      }
    }

    .rule-item:nth-last-child(1) {
      margin-right: 0;
    }
  }
}
