
@import "~@/common/less/variable.less";
@import "~@/common/less/base.less";

.sku-dialog {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding-bottom: 0.98rem;
  box-sizing: border-box;

  .sku-main {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding-bottom: 0.98rem;
    box-sizing: border-box;
  }

  .sku-content {
    width: 100%;
    background: #fff;
    padding: 0.3rem;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    z-index: 999;

    .close {
      position: absolute;
      width: 0.32rem;
      height: 0.32rem;
      border-radius: 0.32rem;
      right: 0.4rem;
      top: 0.4rem;

      img {
        width: 0.32rem;
        height: 0.32rem;
        border-radius: 0.32rem;
      }
    }

    .goods-box {
      padding-bottom: 0.3rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e1e1e1;

      .goods-img {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 8px;

        img {
          width: 1.8rem;
          height: 1.8rem;
        }
      }

      .goods-info {
        padding-top: 0.3rem;
        box-sizing: border-box;
        color: #333;

        .price {
          margin-bottom: 0.24rem;

          .zjg-max {
            color: var(--main-color);
            font-size: 0.2rem !important;
          }

          span:nth-child(1) {
            font-size: 0.36rem;
          }

          span:nth-child(2) {
            font-size: 0.5rem;
          }
        }

        .stock,
        .num {
          font-size: 0.24rem;
          line-height: 0.34rem;
        }

        .fs-weight {
          font-weight: 500;
        }
      }
    }

    .goods-sku {
      // padding: 0.3rem 0 1rem;
      padding-top: 0.3rem;
      box-sizing: border-box;
      max-height: 6.68rem;
      overflow-y: auto;

      .sku-item {
        margin-bottom: 0.2rem;

        .sku-property {
          font-size: 0.28rem;
          color: #333333;
          line-height: 0.4rem;
          margin-bottom: 0.2rem;
        }

        .sku-value-box {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .sku-value {
            padding: 0.1rem 0.16rem;
            box-sizing: border-box;
            background: #f5f5f5;
            border-radius: 4px;
            font-size: 0.22rem;
            color: #333;
            margin-right: 0.3rem;
            margin-bottom: 0.1rem;
            border: 1px solid transparent;
          }

          .active {
            border: 1px solid var(--main-color);
            color: var(--main-color);
          }

          .noUse {
            color: #999999;
          }
        }
      }
    }

    .int-deducion {
      margin: 0.1rem;
    }

    .integral-input {
      padding: 0.2rem 0;

      .the-input {
        display: flex;
        margin-bottom: 0.2rem;

        .left {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          color: #555;
          font-size: 0.28rem;
          white-space: nowrap;
        }

        .right {
          width: 60%;
          min-height: 0.5rem;
          border: 1px solid #eef1f5;
          color: #525252;
          text-align: right;
          font-size: 0.26rem;
          // padding: 0 .24rem;
          padding-left: 0.1rem;
          -webkit-box-pack: end;
          justify-content: flex-end;
        }

        .btn {
          display: flex;
          align-items: center;
          margin-left: 0.2rem;

          .yd-btn-primary {
            background: var(--main-color);
          }
        }

        .kunshan-tip {
          color: red;
          font-size: 0.25rem;
          margin-left: 0.1rem;
        }
      }

      .tip {
        color: red;
        font-size: 0.25rem;
        line-height: 0.2rem;
      }
    }

    .goods-num {
      padding: 0.3rem 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #e1e1e1;

      .num-title {
        font-size: 0.28rem;
        color: #333333;
      }

      .num-spinner {
        display: flex;
        align-items: center;

        img {
          width: 0.52rem;
          height: 0.52rem;
          border-radius: 0.52rem;
        }

        input {
          border: none;
          font-size: 0.36rem;
          color: #333333;
          text-align: center;
          width: 0.8rem;
        }
      }
    }
  }

  .sku-btn {
    width: 100%;
    height: 0.98rem;
    position: fixed;
    bottom: 0;
    text-align: center;
    line-height: 0.98rem;
    font-size: 0.32rem;
    color: #fff;
    background: var(--main-color);
    z-index: 991;
  }

  .no-stock {
    background: #999999;
  }
}
