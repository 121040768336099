
.banner {
  width: 7.5rem;
  height: 7.5rem;
  position: relative;
  .img-box {
    width: 7.5rem;
    height: 7.5rem;
    .img {
      width: 7.5rem;
      height: 7.5rem;
    }
  }
  .home-icon,
  .collect-icon {
    width: 0.56rem;
    height: 0.56rem;
    border-radius: 0.56rem;
    position: absolute;
    z-index: 110;

    img {
      width: 0.56rem;
      height: 0.56rem;
    }
  }

  .home-icon {
    left: 0.3rem;
    top: 0.4rem;
  }

  .collect-icon {
    right: 0.3rem;
    top: 0.4rem;
  }
}
