.banner {
  width: 7.5rem;
  height: 7.5rem;
  position: relative;
}
.banner .img-box {
  width: 7.5rem;
  height: 7.5rem;
}
.banner .img-box .img {
  width: 7.5rem;
  height: 7.5rem;
}
.banner .home-icon,
.banner .collect-icon {
  width: 0.56rem;
  height: 0.56rem;
  border-radius: 0.56rem;
  position: absolute;
  z-index: 110;
}
.banner .home-icon img,
.banner .collect-icon img {
  width: 0.56rem;
  height: 0.56rem;
}
.banner .home-icon {
  left: 0.3rem;
  top: 0.4rem;
}
.banner .collect-icon {
  right: 0.3rem;
  top: 0.4rem;
}
