.flash-sale {
  width: 100%;
  height: 1rem;
  display: flex;
}
.flash-sale .goods-info {
  width: 5.46rem;
  height: 1rem;
  display: flex;
  align-items: center;
  padding-left: 0.24rem;
  box-sizing: border-box;
}
.flash-sale .goods-info .tip {
  width: 1.44rem;
  height: 0.48rem;
  border-radius: 2px;
  background: #fff;
  font-size: 0.32rem;
  text-align: center;
  line-height: 0.48rem;
  margin-right: 0.3rem;
}
.flash-sale .goods-info .goods-price {
  display: flex;
  color: #fff;
  align-items: baseline;
}
.flash-sale .goods-info .goods-price .real-price {
  font-weight: bold;
  margin-right: 5px;
}
.flash-sale .goods-info .goods-price .real-price span:nth-child(1) {
  font-size: 0.3rem;
}
.flash-sale .goods-info .goods-price .real-price span:nth-child(2) {
  font-size: 0.3rem;
}
.flash-sale .goods-info .goods-price s {
  font-size: 0.16rem;
}
.flash-sale .redLeft {
  background: linear-gradient(227deg, #ff4444 0%, #fc3e6c 100%);
  color: #fd4060;
}
.flash-sale .greenLeft {
  background: #00b94c;
  color: #00b94c;
}
.flash-sale .time-box {
  flex: 1;
  height: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flash-sale .time-box .time-title {
  font-size: 0.22rem;
  line-height: 0.32rem;
}
.flash-sale .time-box .time {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flash-sale .time-box .time .time-item {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 2px;
  text-align: center;
  font-size: 0.26rem;
  line-height: 0.4rem;
  color: #fff;
}
.flash-sale .time-box .time span {
  margin-left: 2px;
  margin-right: 2px;
}
.flash-sale .redRight {
  background: #ffeceb;
  color: #fd4060;
}
.flash-sale .greenRight {
  background: #baffd6;
  color: #00b94c;
}
