.group-list .list-top {
  width: 100%;
  height: 0.8rem;
  background: #f8f8f8;
  padding: 0 0.3rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.group-list .list-top .tip {
  font-size: 0.24rem;
  color: #999999;
}
.group-list .list-top .look-more {
  font-size: 0.24rem;
  color: #666666;
}
.group-list .list-content {
  padding: 0 0.3rem;
  box-sizing: border-box;
  background: #fff;
}
.group-list .list-content .list-item {
  height: 1.4rem;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.group-list .list-content .list-item .item-info {
  display: flex;
  align-items: center;
}
.group-list .list-content .list-item .item-info .user-img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.8rem;
  margin-right: 0.2rem;
}
.group-list .list-content .list-item .item-info .user-img img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.8rem;
}
.group-list .list-content .list-item .item-info .user-info {
  height: 0.8rem;
}
.group-list .list-content .list-item .item-info .user-info .user-name {
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.4rem;
  margin-bottom: 3px;
}
.group-list .list-content .list-item .item-info .user-info .count-down {
  font-size: 0.24rem;
  color: #999999;
}
.group-list .list-content .list-item .item-btn {
  width: 1.28rem;
  height: 0.48rem;
  border-radius: 0.48rem;
}
.group-list .list-content .list-item .item-btn img {
  width: 1.28rem;
  height: 0.48rem;
}
.group-list .list-content .list-item:nth-last-child(1) {
  border-bottom: none;
}
