.group-play {
  height: 0.94rem;
  border-top: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.group-play .group-img {
  width: 1.12rem;
  height: 0.34rem;
}
.group-play .group-img img {
  width: 1.12rem;
  height: 0.34rem;
}
.group-play .group-rule {
  height: 0.94rem;
  display: flex;
  align-items: center;
}
.group-play .group-rule .rule-item {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.group-play .group-rule .rule-item div {
  width: 0.28rem;
  height: 0.28rem;
  border-radius: 0.28rem;
  background: var(--main-color);
  font-size: 0.22rem;
  color: #fff;
  margin-right: 2px;
  text-align: center;
  line-height: 0.28rem;
}
.group-play .group-rule .rule-item p {
  font-size: 0.22rem;
  color: #666666;
}
.group-play .group-rule .rule-item:nth-last-child(1) {
  margin-right: 0;
}
