.evaluate-list .item {
  width: 6.9rem;
  padding: 0.24rem 0.2rem;
  box-sizing: border-box;
}
.evaluate-list .item .item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.48rem;
  margin-bottom: 0.2rem;
}
.evaluate-list .item .item-top .user {
  display: flex;
  align-items: center;
}
.evaluate-list .item .item-top .user img {
  width: 0.48rem;
  height: 0.48rem;
  border-radius: 0.48rem;
  margin-right: 5px;
}
.evaluate-list .item .item-top .user span {
  font-size: 0.24rem;
  color: #999999;
}
.evaluate-list .item .item-content {
  font-size: 0.24rem;
  color: #333333;
  line-height: 0.34rem;
  margin-bottom: 0.2rem;
}
.evaluate-list .item .img-box {
  display: flex;
  align-items: center;
}
.evaluate-list .item .img-box img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}
