body,
html {
  line-height: 1;
  font-family: 'PingFang SC', 'STHeitiSC-Light', 'Helvetica-Light', arial, sans-serif, 'Droid Sans Fallback';
  -webkit-tap-highlight-color: transparent;
}
path {
  fill: inherit !important;
}
.main-color {
  color: var(--main-color);
}
.yd-cell-right input {
  line-height: 100% !important;
  color: #333 !important;
}
.border-top-1px,
.border-right-1px,
.border-bottom-1px,
.border-left-1px {
  position: relative;
}
.border-top-1px:before,
.border-right-1px:before,
.border-bottom-1px:before,
.border-left-1px:before,
.border-top-1px:after,
.border-right-1px:after,
.border-bottom-1px:after,
.border-left-1px:after {
  content: "";
  display: block;
  position: absolute;
  transform-origin: 0 0;
  z-index: 3;
}
.border-top-1px:before {
  border-top: 1px solid #e1e1e1;
  left: 0;
  top: 0;
  width: 100%;
  transform-origin: 0 top;
}
.border-right-1px:after {
  border-right: 1px solid #f5f5f5;
  top: 0;
  right: 0;
  height: 100%;
  transform-origin: right 0;
}
.border-bottom-1px:after {
  border-bottom: 1px solid #e1e1e1;
  left: 0;
  bottom: 0;
  width: 100%;
  transform-origin: 0 bottom;
}
.border-left-1px:before {
  border-left: 1px solid #f5f5f5;
  top: 0;
  left: 0;
  height: 100%;
  transform-origin: left 0;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
  .border-top-1px:before {
    width: 200%;
    transform: scale(0.5) translateZ(0);
  }
  .border-right-1px:after {
    height: 200%;
    transform: scale(0.5) translateZ(0);
  }
  .border-bottom-1px:after {
    width: 200%;
    transform: scale(0.5) translateZ(0);
  }
  .border-left-1px:before {
    height: 200%;
    transform: scale(0.5) translateZ(0);
  }
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .border-top-1px:before {
    width: 300%;
    transform: scale(0.333) translateZ(0);
  }
  .border-right-1px:after {
    height: 300%;
    transform: scale(0.333) translateZ(0);
  }
  .border-bottom-1px:after {
    width: 300%;
    transform: scale(0.333) translateZ(0);
  }
  .border-left-1px:before {
    height: 300%;
    transform: scale(0.333) translateZ(0);
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.df {
  display: flex;
}
.alc {
  align-items: center;
}
.als {
  align-items: flex-start;
}
.jc-fs {
  justify-content: flex-start;
}
.jcc {
  justify-content: center;
}
.jc-sb {
  justify-content: space-between;
}
.jc-sa {
  justify-content: space-around;
}
.jc-end {
  justify-content: flex-end;
}
.fww {
  flex-wrap: wrap;
}
.fdc {
  flex-direction: column;
}
.fdr {
  flex-direction: row;
}
.bg-transparent {
  background: transparent !important;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.slide-top-enter,
.slide-top-leave-to {
  transform: translate(0, 100%);
}
.slide-top-enter-active,
.slide-top-leave-active {
  transition: all 0.3s ease;
}
.txt-over {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sku-dialog {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding-bottom: 0.98rem;
  box-sizing: border-box;
}
.sku-dialog .sku-main {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 0.98rem;
  box-sizing: border-box;
}
.sku-dialog .sku-content {
  width: 100%;
  background: #fff;
  padding: 0.3rem;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  z-index: 999;
}
.sku-dialog .sku-content .close {
  position: absolute;
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 0.32rem;
  right: 0.4rem;
  top: 0.4rem;
}
.sku-dialog .sku-content .close img {
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 0.32rem;
}
.sku-dialog .sku-content .goods-box {
  padding-bottom: 0.3rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
}
.sku-dialog .sku-content .goods-box .goods-img {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 8px;
}
.sku-dialog .sku-content .goods-box .goods-img img {
  width: 1.8rem;
  height: 1.8rem;
}
.sku-dialog .sku-content .goods-box .goods-info {
  padding-top: 0.3rem;
  box-sizing: border-box;
  color: #333;
}
.sku-dialog .sku-content .goods-box .goods-info .price {
  margin-bottom: 0.24rem;
}
.sku-dialog .sku-content .goods-box .goods-info .price .zjg-max {
  color: var(--main-color);
  font-size: 0.2rem !important;
}
.sku-dialog .sku-content .goods-box .goods-info .price span:nth-child(1) {
  font-size: 0.36rem;
}
.sku-dialog .sku-content .goods-box .goods-info .price span:nth-child(2) {
  font-size: 0.5rem;
}
.sku-dialog .sku-content .goods-box .goods-info .stock,
.sku-dialog .sku-content .goods-box .goods-info .num {
  font-size: 0.24rem;
  line-height: 0.34rem;
}
.sku-dialog .sku-content .goods-box .goods-info .fs-weight {
  font-weight: 500;
}
.sku-dialog .sku-content .goods-sku {
  padding-top: 0.3rem;
  box-sizing: border-box;
  max-height: 6.68rem;
  overflow-y: auto;
}
.sku-dialog .sku-content .goods-sku .sku-item {
  margin-bottom: 0.2rem;
}
.sku-dialog .sku-content .goods-sku .sku-item .sku-property {
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.4rem;
  margin-bottom: 0.2rem;
}
.sku-dialog .sku-content .goods-sku .sku-item .sku-value-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.sku-dialog .sku-content .goods-sku .sku-item .sku-value-box .sku-value {
  padding: 0.1rem 0.16rem;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 0.22rem;
  color: #333;
  margin-right: 0.3rem;
  margin-bottom: 0.1rem;
  border: 1px solid transparent;
}
.sku-dialog .sku-content .goods-sku .sku-item .sku-value-box .active {
  border: 1px solid var(--main-color);
  color: var(--main-color);
}
.sku-dialog .sku-content .goods-sku .sku-item .sku-value-box .noUse {
  color: #999999;
}
.sku-dialog .sku-content .int-deducion {
  margin: 0.1rem;
}
.sku-dialog .sku-content .integral-input {
  padding: 0.2rem 0;
}
.sku-dialog .sku-content .integral-input .the-input {
  display: flex;
  margin-bottom: 0.2rem;
}
.sku-dialog .sku-content .integral-input .the-input .left {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #555;
  font-size: 0.28rem;
  white-space: nowrap;
}
.sku-dialog .sku-content .integral-input .the-input .right {
  width: 60%;
  min-height: 0.5rem;
  border: 1px solid #eef1f5;
  color: #525252;
  text-align: right;
  font-size: 0.26rem;
  padding-left: 0.1rem;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.sku-dialog .sku-content .integral-input .the-input .btn {
  display: flex;
  align-items: center;
  margin-left: 0.2rem;
}
.sku-dialog .sku-content .integral-input .the-input .btn .yd-btn-primary {
  background: var(--main-color);
}
.sku-dialog .sku-content .integral-input .the-input .kunshan-tip {
  color: red;
  font-size: 0.25rem;
  margin-left: 0.1rem;
}
.sku-dialog .sku-content .integral-input .tip {
  color: red;
  font-size: 0.25rem;
  line-height: 0.2rem;
}
.sku-dialog .sku-content .goods-num {
  padding: 0.3rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e1e1e1;
}
.sku-dialog .sku-content .goods-num .num-title {
  font-size: 0.28rem;
  color: #333333;
}
.sku-dialog .sku-content .goods-num .num-spinner {
  display: flex;
  align-items: center;
}
.sku-dialog .sku-content .goods-num .num-spinner img {
  width: 0.52rem;
  height: 0.52rem;
  border-radius: 0.52rem;
}
.sku-dialog .sku-content .goods-num .num-spinner input {
  border: none;
  font-size: 0.36rem;
  color: #333333;
  text-align: center;
  width: 0.8rem;
}
.sku-dialog .sku-btn {
  width: 100%;
  height: 0.98rem;
  position: fixed;
  bottom: 0;
  text-align: center;
  line-height: 0.98rem;
  font-size: 0.32rem;
  color: #fff;
  background: var(--main-color);
  z-index: 991;
}
.sku-dialog .no-stock {
  background: #999999;
}
