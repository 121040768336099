
.evaluate-list {
  .item {
    width: 6.9rem;
    padding: 0.24rem 0.2rem;
    box-sizing: border-box;

    .item-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 0.48rem;
      margin-bottom: 0.2rem;

      .user {
        display: flex;
        align-items: center;

        img {
          width: 0.48rem;
          height: 0.48rem;
          border-radius: 0.48rem;
          margin-right: 5px;
        }

        span {
          font-size: 0.24rem;
          color: #999999;
        }
      }
    }

    .item-content {
      font-size: 0.24rem;
      color: #333333;
      line-height: 0.34rem;
      margin-bottom: 0.2rem;
    }

    .img-box {
      display: flex;
      align-items: center;

      img {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.2rem;
      }
    }
  }
}
