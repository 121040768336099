
.yqt-image {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.yqt-image__inner {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.yqt-image__inner--center {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
}
