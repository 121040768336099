
.flash-sale {
  width: 100%;
  height: 1rem;
  display: flex;

  .goods-info {
    width: 5.46rem;
    height: 1rem;
    display: flex;
    align-items: center;
    padding-left: 0.24rem;
    box-sizing: border-box;

    .tip {
      width: 1.44rem;
      height: 0.48rem;
      border-radius: 2px;
      background: #fff;
      font-size: 0.32rem;
      text-align: center;
      line-height: 0.48rem;
      margin-right: 0.3rem;
    }

    .goods-price {
      display: flex;
      color: #fff;
      align-items: baseline;

      .real-price {
        font-weight: bold;
        margin-right: 5px;

        span:nth-child(1) {
          font-size: 0.3rem;
        }

        span:nth-child(2) {
          font-size: 0.3rem;
        }
      }

      s {
        font-size: 0.16rem;
      }
    }
  }

  .redLeft {
    background: linear-gradient(
        227deg,
        rgba(255, 68, 68, 1) 0%,
        rgba(252, 62, 108, 1) 100%
    );
    color: #fd4060;
  }

  .greenLeft {
    background: rgba(0, 185, 76, 1);
    color: #00b94c;
  }

  .time-box {
    flex: 1;
    height: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .time-title {
      font-size: 0.22rem;
      line-height: 0.32rem;
    }

    .time {
      display: flex;
      align-items: center;
      justify-content: center;

      .time-item {
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 2px;
        text-align: center;
        font-size: 0.26rem;
        line-height: 0.4rem;
        color: #fff;
      }

      span {
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }

  .redRight {
    background: rgba(255, 236, 235, 1);
    color: #fd4060;
  }

  .greenRight {
    background: rgba(186, 255, 214, 1);
    color: #00b94c;
  }
}
